<template>
 <div>
    <div class="my-5 text-center gap-2 flex">
        <!-- <input type="text" name="from" id="from" placeholder="از تاریخ" v-model="fromDate" class="border-0 rounded bg-grey-light px-2 py-2 text-black"> -->
        <!-- <input type="text" name="from" id="from" placeholder="تا تاریخ" v-model="toDate" class="border-0 rounded bg-grey-light px-2 py-2 text-black"> -->

        <vue-button-spinner
          :is-loading="isLoading"
          :disabled="isLoading"
          >
          <span @click="getLogs()" >
          به روزرسانی اطلاعات
          </span>

        </vue-button-spinner>
    </div>

    <div v-if="isData">
        <div class="flex text-white bg-primary py-4 px-4 rounded my-2 text-sm text-center" >
            <span class="w-32 border-solid border-0 border-r border-white border-opacity-25 "> شماره ماشین  </span>
            <span class="w-32 border-solid border-0 border-r border-white border-opacity-25 "> آی پی </span>
            <span class="w-32 border-solid border-0 border-r border-white border-opacity-25 ">  ترافیک دریافتی در ساعت </span>
            <span class="w-32 border-solid border-0 border-r border-white border-opacity-25 ">  ترافیک ارسالی در ساعت</span>
            <span class="w-32 border-solid border-0 border-r border-white border-opacity-25 "> مجموع ترافیک ارسالی و دریافتی در ساعت</span>
            <span class="w-32 border-solid border-0 border-r border-white border-opacity-25 "> سرعت دریافت (Mbit/s) </span>
            <span class="w-32 border-solid border-0 border-r border-white border-opacity-25 "> سرعت ارسال (Mbit/s) </span>
            <span class="w-32 border-solid border-0 border-r border-white border-opacity-25 "> node </span>
            <span class="w-32 border-solid border-0 border-r border-white border-opacity-25 "> تاریخ ساخت </span>
            <span class="w-32 border-solid border-0 border-r border-white border-opacity-25 "> تاریخ گزارش </span>

        </div>
        <div class="flex text-white bg-primary py-4 px-4 rounded my-2 text-sm text-center" :key="log" v-for="log in logs">
            <span class="w-32 border-solid border-0 border-r border-white border-opacity-25 "> {{ log.vm_info != '' ? log.vm_info.vm_sequence_id : '-' }} </span>
            <span class="w-32 border-solid border-0 border-r border-white border-opacity-25 "> {{ log.ip_address }} </span>
            <span dir="ltr" class="w-32 border-solid border-0 border-r border-white border-opacity-25 "> {{ log.total_received_hour }} </span>
            <span dir="ltr" class="w-32 border-solid border-0 border-r border-white border-opacity-25 "> {{ log.total_sent_hour }} </span>
            <span dir="ltr" class="w-32 border-solid border-0 border-r border-white border-opacity-25 "> {{ log.sum_traffic_hour }} </span>
            <span dir="ltr" class="w-32 border-solid border-0 border-r border-white border-opacity-25 "> {{ log.bw_speed_received }} </span>
            <span dir="ltr" class="w-32 border-solid border-0 border-r border-white border-opacity-25 "> {{ log.bw_speed_send }} </span>
            <span dir="ltr" class="w-32 border-solid border-0 border-r border-white border-opacity-25 "> {{ log.node_id }} </span>
            <span class="w-32 border-solid border-0 border-r border-white border-opacity-25 "> {{ log.vm_info.created_at }} </span>
            <span class="w-32 border-solid border-0 border-r border-white border-opacity-25 "> {{ log.last_collected_at }} </span>


        </div>
    </div>
 </div>
</template>

<script>
import VueButtonSpinner from 'vue-button-spinner'

    export default {
        components: {
          VueButtonSpinner
        },
        data: () => ({
            fromDate:'',
            toDate:'',
            logs:[],
            isData:false,
            isLoading: false,
            status: '',
        }),

        methods: {
            getLogs() {
                 this.isLoading = true
                 this.$http.post(
                    '/admin/cloud/traffic/network/speed?per_page=100', { type: 1, last_hours: 1 }
                 ).then((res) => {
                    this.isLoading = false
                    this.status = false // or success
                    this.logs = res.data.params.data;
                    this.isData = true
                 }).catch(error => {
                    this.$vs.notify({
                      text: error.response.data.message,
                      color: "danger",
                    });
                    this.isLoading = false
                    this.status = true //or error
                 })
            }
        },

        created() {
          this.getLogs()
        }

    }
</script>
